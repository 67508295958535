import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { TextField, Select } from '../lib/common';
import DeleteButton from './delete-button';

const ClientBankForm = (props) => {
  const { bank, update } = props;
  const [bank_name, setBankName] = useState(bank.bank_name);
  const [type, setType] = useState(bank.type);
  const [name_on_account, setNameOnAccount] = useState(bank.name_on_account);
  const [account_number, setAccountNumber] = useState(bank.account_number);
  const [routing_number, setRoutingNumber] = useState(bank.routing_number);

  const handleTextChange = (e, setter) => {
    const { name, value } = e.target || e;
    const data = {
      bank_name,
      type,
      name_on_account,
      account_number,
      routing_number,
    };
    update({ ...bank, ...data, [name]: value });
    setter(value);
  };

  const handleDelete = () => {
    bank.to_delete = true;
    update(bank);
  };

  return (
    <>
      <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Bank {'#' + (props.index + 1)}
        <DeleteButton handleDelete={handleDelete} iconStyle={{ marginLeft: '15px' }}/>
      </Typography>

      <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} minWidth={985}>
        <Box gridColumn="span 5">
          <TextField
            label="Bank Name"
            id="type"
            name="type"
            value={bank_name}
            onChange={(e) => handleTextChange(e, setBankName)}
          />
        </Box>
        <Box gridColumn="span 6">
          <TextField
            label="Name(s) on Account"
            id="name_on_account"
            name="name_on_account"
            value={name_on_account}
            onChange={(e) => handleTextChange(e, setNameOnAccount)}
          />
        </Box>
        <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
          <DeleteButton handleDelete={handleDelete}/>
        </Box>
        <Box gridColumn="span 3">
          <Select
            label="Type *"
            value={type}
            name="type"
            onChange={(e) => handleTextChange(e, setType)}
          >
            <MenuItem value="PERSONAL_CHECKING">Personal Checking</MenuItem>
            <MenuItem value="PERSONAL_SAVINGS">Personal Savings</MenuItem>
            <MenuItem value="BUSINESS_CHECKING">Business Checking</MenuItem>
            <MenuItem value="BUSINESS_SAVINGS">Business Savings</MenuItem>
            <MenuItem value="TRUST_CHECKING">Trust Checking</MenuItem>
            <MenuItem value="TRUST_SAVINGS">Trust Savings</MenuItem>
          </Select>
        </Box>
        <Box gridColumn="span 3">
          <TextField
            label="Account Number"
            id="account_number"
            name="account_number"
            value={account_number}
            onChange={(e) => handleTextChange(e, setAccountNumber)}
          />
        </Box>
        <Box gridColumn="span 3">
          <TextField
            label="Routing Number"
            id="routing_number"
            name="routing_number"
            value={routing_number}
            onChange={(e) => handleTextChange(e, setRoutingNumber)}
          />
        </Box>
      </Box>
    </>
  );
};

export default ClientBankForm;

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { pick, sortBy } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import useSWR from 'swr';
import moment from 'moment';
import DownloadButton from './download-csv';
import { Select } from '../lib/common';
import useUser from '../lib/useUser';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'age',
    headerName: 'Age',
    align: 'right',
    width: 80,
  },
  {
    field: 'dob',
    headerName: 'DOB',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return moment(params.row.dob).format('MM/DD/YYYY');
    },
  },
  {
    field: 'address',
    headerName: 'Address',
    align: 'left',
    width: 200,
  },
  {
    field: 'city',
    headerName: 'City',
    align: 'left',
    width: 150,
  },
  {
    field: 'state',
    headerName: 'State',
    align: 'left',
    width: 50,
  },
  {
    field: 'zip',
    headerName: 'Zip',
    align: 'left',
    width: 80,
  },
  {
    field: 'type',
    headerName: '',
    align: 'right',
    width: 50,
    renderCell: (params) => {
      return <Link to={`/household/${params.row.household_id}/client/${params.row.id}`}>edit</Link>;
    },
  },
];

const ClientRMD = () => {
  const user = useUser();
  const classes = useStyles();
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);

  const allYears = [];
  for (let i = 70; i <= 85; i++) {
    allYears.push(i);
  }
  const allMonths = [];
  for (let i = 0; i <= 11; i++) {
    allMonths.push(i);
  }

  const path = `/users/${user.id}/clients/age/${years}/${months}`;
  const { data, loading } = useSWR(path);
  let rows = (data || []).map((r) => {
    r.age_years = moment().diff(r.dob, 'years', false);
    r.age_months = moment().diff(r.dob, 'months', false) % 12;
    if (r.maddress) {
      r.address = r.maddress;
      r.city = r.mcity;
      r.state = r.mstate;
      r.zip = r.mzip;
    } else {
      r.address = r.paddress;
      r.city = r.pcity;
      r.state = r.pstate;
      r.zip = r.pzip;
    }
    r.age = `${r.age_years}y ${r.age_months}m`;
    return pick(r, [
      'id',
      'firstname',
      'middlename',
      'lastname',
      'dob',
      'age',
      'email',
      'age_years',
      'age_months',
      'address',
      'city',
      'state',
      'zip',
    ]);
  });
  rows = sortBy(rows, ['lastname', 'firstname']);

  const waitDisp = years ? <CircularProgress/> : '';

  return (
    <div>
      <Typography variant="h4">Required Minimum Distribution</Typography>
      <div style={{ display: 'flex' }}>
        <Select
          value={years}
          label="Years"
          name="selectType"
          onChange={(e) => setYears(e.target.value)}
          style={{ width: 150, margin: 15 }}
        >
          {allYears.map((y) => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={months}
          label="Months"
          name="selectType"
          onChange={(e) => setMonths(e.target.value)}
          style={{ width: 150, margin: 15 }}
        >
          {allMonths.map((m) => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </Select>
      </div>
      {!loading && years ? (
        <>
          <div>
            <DownloadButton rows={rows} name="ClientRMD"/>
          </div>
          <div className={classes.gridContainer}>
            <DataGrid columns={columns} rows={rows} />
          </div>
        </>
      ) : waitDisp}
    </div>
  );
};

export default ClientRMD;

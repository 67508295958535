import React from 'react';
import { Button } from '@mui/material';

// eslint-disable-next-line
const CONTRACTING_URL = 'https://accounts.surancebay.com/oauth/authorize?redirect_uri=https:%2F%2Fsurelc.surancebay.com%2Fproducer%2Foauth%3FreturnUrl%3D%252Fprofile%252Fcontact-info%253FgaId%253D145%2526gaId%253D145%2526branch%253DStronghold%252520Wealth%252520Partners%2526branchVisible%253Dtrue%2526branchEditable%253Dfalse%2526branchRequired%253Dtrue%2526autoAdd%253Dfalse%2526requestMethod%253DGET&gaId=145&client_id=surecrmweb&response_type=code';

const Contracting = () => {
  return (
    <div
      style={{
        margin: '10px',
      }}
    >
      <Button
        href={CONTRACTING_URL}
        target="_blank"
        rel="noopener noreferrer"
        gold
      >
        SuranceBay Contracting
      </Button>
    </div>
  );
};

export default Contracting;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { omit, orderBy } from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import CopyIcon from '@mui/icons-material/CopyAll';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { colors } from '../lib/styles';
import ClientForm from '../components/client-form';
import ClientEmploymentList from '../components/client-employment';
import ClientBeneficiariesList from '../components/client-beneficiaries';
import ClientTrustsList from '../components/client-trusts';
import ClientAdvisorsList from '../components/client-advisors';
import ClientTermInsuranceList from '../components/client-term-insurance';
import ClientIncomeList from '../components/client-income';
import ClientRealEstateList from '../components/client-real_estate';
import ClientBankingList from '../components/client-banks';
import ClientAssetsList from '../components/client-assets';
import ClientBSFs from '../components/client-bsfs';
import ClientDocs from '../components/client-documents';
import HouseholdNotesList from '../components/household-notes';
import { apiFetch } from '../lib/fetch';
import useUser from '../lib/useUser';
import logClient from '../lib/logClient';
import {
  updateDefaults,
  newClient,
  addAssetChildren,
  newEmployment,
} from '../lib/initializers';
import Layout from '../components/layout';
import { Button } from '../lib/common';
import HouseholdSettings from '../components/household-settings';
import { submissionForms } from '../lib/applicationForms';
import DupeClients from '../components/dupe-clients';

const SIDE_NAV_WIDTH = 180;

const styles = {
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  content: {
    width: '100%',
    paddingLeft: SIDE_NAV_WIDTH,
  },
  btnText: {
    color: colors.black,
    fontSize: 14,
  },
  sideNav: {
    backgroundColor: colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 100,
    top: 90,
    textAlign: 'center',
    height: 'calc(100vh - 85px)',
    width: SIDE_NAV_WIDTH,
    borderRight: `1px solid ${colors.blackOpacity}`,
    overflowY: 'scroll',
    paddingBottom: 25,
  },
  hashLink: {
    fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
    textAlign: 'start',
    padding: '12px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
  },
  errorIcon: {
    color: 'red',
    fontSize: '16px',
    margin: '3px',
  },
  errMsg: {
    margin: '15px',
    color: 'red',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  navBtn: {
    minWidth: '36px',
    padding: '3px',
    margin: '5px',
    backgroundColor: colors.primaryColor,
    color: colors.white,
    borderRadius: '5px',
  },
  btnContainer: {
    marginTop: 8,
  },
};

const useStyles = makeStyles(styles);

const Household = () => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { householdId, clientId, nav } = params;
  const [household, setHousehold] = useState();
  const [currentNotes, setCurrentNotes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [configs, setConfigs] = useState({});
  const user = useUser();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [viewSettings, setViewSettings] = useState(false);
  const [confirmAddClient, setConfirmAddClient] = useState(false);
  const [displays, setDisplays] = useState({
    hh: {},
  });
  const [scpErrors, setScpErrors] = useState([]);
  const [bsfErrors, setBsfErrors] = useState([]);
  const [openSubmissionsDialog, setOpenSubmissionsDialog] = useState(false);
  const [openBeneficiaryDialog, setOpenBeneficiaryDialog] = useState(false);
  const [beneficiaryWarning, setBeneficiaryWarning] = useState('');
  const [scpClient, setScpClient] = useState(null);
  const [formLink, setFormLink] = useState('');

  const personalRef = useRef(null);
  const employmentRef = useRef(null);
  const beneficiariesRef = useRef(null);
  const trustsRef = useRef(null);
  const incomeRef = useRef(null);
  const assetsRef = useRef(null);
  const insuranceRef = useRef(null);
  const realEstateRef = useRef(null);
  const banksRef = useRef(null);
  const trustedAdvisorsRef = useRef(null);
  const notesRef = useRef(null);
  const bsfRef = useRef(null);
  const clientDocsRef = useRef(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  useEffect(async () => {
    const householdRecord = await apiFetch(`/users/${user.id}/household/${householdId}/clients`);
    const ct = await apiFetch('/clients/config_types');
    setConfigs(ct);
    const clients = householdRecord.clients || [];
    householdRecord.clients = [];
    if (householdRecord.primary_client) {
      clients.forEach((c) => {
        if (c.id === householdRecord.primary_client) {
          householdRecord.clients.push(c);
        }
      });
    }
    clients.forEach((c) => {
      if (c.id !== householdRecord.primary_client) {
        householdRecord.clients.push(c);
      }
    });
    householdRecord.clients = householdRecord.clients || [];
    householdRecord.clients.forEach((c) => {
      const cDisplay = displays[c.id] || {};
      displays[c.id] = cDisplay;
      if (clientId === c.id) {
        cDisplay.display_header = true;
        cDisplay.display_employment = true;
        logClient(user.id, clientId, 'READ');
      }
      c.isNew = false;
      cDisplay.age_years = moment().diff(c.dob, 'years', false);
      cDisplay.age_months = moment().diff(c.dob, 'months', false) % 12;
      c.records.assets.forEach(addAssetChildren);
    });
    setDisplays({ ...displays });
    setHousehold(householdRecord);
    setCurrentNotes(householdRecord.notes.slice(0, 2));
    if ((householdRecord.access_level === 'OWNER') && (nav === 'settings')) {
      setViewSettings(true);
    }
  }, []);

  const updateAll = () => {
    setHousehold({ ...household });
  };

  const handleAppDialogOpen = (client) => {
    setScpClient(client);
    setOpenSubmissionsDialog(true);
  };

  const handleScpSubmit = (client) => {
    return new Promise((resolve, reject) => {
      const { employmentStatusTypes } = configs;
      const { employment } = client.records;

      const personalInfoErrors = {
        firstname: !client.firstname,
        lastname: !client.lastname,
        dob: !client.dob,
        ssn: !client.ssn,
        paddress: !client.paddress,
        pcity: !client.pcity,
        pstate: !client.pstate,
        pzip: !client.pzip,
        phone: !client.phone,
        email: !client.email,
        gender_id: !client.gender_id,
        marital_status: !client.marital_status,
        dl_num: !client.dl_num,
        dl_issue: !client.dl_issue,
        dl_expiration: !client.dl_expiration,
        dl_state: !client.dl_state,
      };

      // EMPLOYMENT INFORMATION
      const employmentErrors = (() => {
        const emp = employment[0]; // We're only checking the first employment object
        if (!emp) {
          const newEmp = newEmployment();
          employment.push({ ...newEmp, id: 'new' });
          client.records.employment = employment.slice();

          setDisplays({
            ...displays,
            [client.id]: {
              ...displays[client.id],
              display_employment: true,
            },
          });

          return [{ employment_status_id: 'Employment status is required', id: 'new' }];
        }

        const errors = {
          id: emp.id,
        };

        if (!emp.employment_status_id) {
          return [{ employment_status_id: 'Employment status is required', id: emp.id }];
        }

        const { name } = employmentStatusTypes.find((type) => type.id === emp.employment_status_id);

        if (name.includes('No')) return [];

        if (!emp.employer_name) {
          errors.employer_name = 'Employer name is required';
        }

        if (!emp.job_title) {
          errors.job_title = 'Job title is required';
        }

        if (!emp.empaddress) {
          errors.empaddress = 'Employer address is required';
        }

        if (!emp.empcity) {
          errors.empcity = 'Employer city is required';
        }

        if (!emp.empstate) {
          errors.empstate = 'Employer state is required';
        }

        if (!emp.empzip) {
          errors.empzip = 'Employer zip code is required';
        }

        if (!emp.employer_phone) {
          errors.employer_phone = 'Employer phone number is required';
        }

        // Check if errors contains anything other than the id field
        const { id, ...otherFields } = errors;
        return Object.values(otherFields).some((value) => value !== '') ? [errors] : [];
      })();

      const hasPersonalInfoErrors = Object.values(personalInfoErrors).some((value) => value === true);
      const hasEmploymentErrors = employmentErrors.length > 0
        && employmentErrors[0]
        && Object.values(employmentErrors[0]).some((value) => value !== '');

      if (hasPersonalInfoErrors || hasEmploymentErrors) {
        setScpErrors([
          { id: client.id, errors: { ...personalInfoErrors, employment: employmentErrors } },
        ]);

        const err = {};

        if (hasPersonalInfoErrors) {
          err.client = { all: { ctx: 'client', message: 'Please fill in all required fields', path: [] } };
        }

        if (hasEmploymentErrors) {
          err.employment = { all: { ctx: 'employment', message: 'Please fill in all required fields', path: [] } };
        }

        setErrors(err);
        reject(err);
      } else {
        resolve();
      }
    });
  };

  const handleBsfSubmit = (client) => {
    return new Promise((resolve, reject) => {
      const personalInfoErrors = {
        dob: !client.dob,
        pstate: !client.pstate,
      };

      const hasPersonalInfoErrors = Object.values(personalInfoErrors).some((value) => value === true);

      if (hasPersonalInfoErrors) {
        setBsfErrors([
          { id: client.id, errors: { ...personalInfoErrors } },
        ]);

        const err = {};

        if (hasPersonalInfoErrors) {
          err.client = { all: { ctx: 'client', message: 'Please fill in all required fields', path: [] } };
        }

        setErrors(err);
        reject(err);
      } else {
        resolve();
      }
    });
  };

  const handleVerifyBeneficiary = (client, scp_override) => {
    return new Promise((resolve, reject) => {
      if (scp_override) {
        setOpenBeneficiaryDialog(false);
        return resolve();
      }
      const { beneficiaries, trusts } = client.records;
      if (beneficiaries.length === 0 && trusts.length === 0) {
        setOpenBeneficiaryDialog(true);
        setBeneficiaryWarning(
          'No beneficiary or trust information has been indicated for this household. Do you wish to proceed?',
        );

        const other_household_members = household.clients.filter((c) => c.id !== client.id);
        if (other_household_members.length > 0) {
          for (let i = 0; i < other_household_members.length; i++) {
            const other_client = other_household_members[i];
            const { beneficiaries: other_beneficiaries, trusts: other_trusts } = other_client.records;
            if (other_beneficiaries.length > 0 || other_trusts.length > 0) {
              setBeneficiaryWarning(
                <>
                  {/* eslint-disable-next-line max-len */}
                  There is no trust or beneficiary information listed for <strong>{client.firstname} {client.lastname}</strong>. Would you like to use the trust and beneficiary information listed for <strong>{other_client.firstname} {other_client.lastname}</strong>?

                  <p>Click back to enter this information for <strong>{client.firstname}</strong></p>
                  <p>
                    Click proceed to move forward with this information from <strong>{other_client.firstname}</strong>
                  </p>
                </>,
              );
              break;
            }
          }
        }
        return reject();
      }
      return resolve();
    });
  };

  const handleScpForm = (path, scp_override = false) => {
    setFormLink(path);
    setOpenSubmissionsDialog(false);
    handleScpSubmit(scpClient)
      .then(() => handleVerifyBeneficiary(scpClient, scp_override))
      .then(() => window.open(path, '_blank'))
      .catch(() => setOpenSubmissionsDialog(false));
  };

  const handleBsfForm = (client) => {
    handleBsfSubmit(client)
      .then(() => history.push(`/new-bsf/${client.household_id}/${client.id}`))
      .catch(() => setOpenSubmissionsDialog(false));
  };

  function Collapsable(props) {
    const { name, client, ctx } = props;
    const c = client;
    const cDisplay = displays[c.id || 'newclient'] || {};
    async function copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        setSnackbarMessage('Copied to clipboard');
        handleSnackbarOpen();
      } catch (e) {
        // eslint-disable-next-line
        console.error('error copying text', e);
      }
    }
    if (c.isNew && name !== 'display_header') {
      return '';
    }
    return (
      <div id={props.id}>
        <div style={{ display: 'flex' }}>
          <Button
            variant="text"
            startIcon={cDisplay[name] ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            style={{
              textAlign: 'left',
              margin: '5px',
              color: 'black',
            }}
            onClick={() => {
              cDisplay[name] = !cDisplay[name];
              setDisplays({ ...displays });
              updateAll();
            }}
          >
            <div style={{ align: 'left', width: '100%' }}>
              <span>{c.firstname} {c.lastname}</span>
              {c.dob && <span style={{ textTransform: 'none' }}> - {cDisplay.age_years}y {cDisplay.age_months}m</span>}
              {errors[ctx] && (errors[ctx][c.id] || (errors[ctx].all && c.isNew)) ? (
                <Tooltip title="This client has an error">
                  <>
                    <InfoIcon className={classes.errorIcon}/>
                  </>
                </Tooltip>
              ) : ''}
            </div>
          </Button>
          {!c.isNew ? (
            <Typography
              variant="h6"
              fontSize={14}
              style={{
                display: 'flex', alignItems: 'center',
              }}
            >
              Client Number: {c.num}
              <Tooltip title="Copy Client Number">
                <CopyIcon style={{ cursor: 'pointer' }} onClick={() => { copyText(c.num); }}/>
              </Tooltip>
            </Typography>
          ) : ''}
          {(name === 'display_header') && !c.isNew ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => handleBsfForm(c)}
                style={{ marginRight: '5px' }}
              >
                Submit BSF
              </Button>
              <Button
                onClick={() => handleAppDialogOpen(c)}
                style={{ marginRight: '5px' }}
              >
                Submissions
              </Button>
            </div>
          ) : ''}
        </div>
        {cDisplay[name] ? (
          <>
            {errors[ctx]
              && (errors[ctx][c.id] || (errors[ctx].all && c.isNew))
              && cDisplay[name] ? (
                <div className={classes.errMsg}>
                  {(errors[ctx][c.id] || errors[ctx].all).message}
                </div>
              ) : ''}
            <div>
              {props.children}
            </div>
          </>
        ) : ''}
      </div>
    );
  }

  const scrollWithOffset = (ref) => {
    const yCoordinate = ref.current?.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  function ScrollLink(props) {
    const { ctx, reff, title } = props;
    return (
      <button
        type="button"
        onClick={() => scrollWithOffset(reff)}
        className={classes.hashLink}
      >
        <span className={classes.btnText}>{title}</span>
        {errors[ctx] ? (
          <Tooltip title="This section has error(s)">
            <>
              <InfoIcon className={classes.errorIcon}/>
            </>
          </Tooltip>
        ) : ''}
      </button>
    );
  }

  const updateLocalHH = (hh) => {
    const prevHHRecord = household;
    hh.clients.forEach((c) => {
      const cDisplay = displays[c.id] || {};
      displays[c.id] = cDisplay;
      c.isNew = false;
      cDisplay.age_years = moment().diff(c.dob, 'years', false);
      cDisplay.age_months = moment().diff(c.dob, 'months', false) % 12;
      c.records.assets.forEach(addAssetChildren);
      let prevClient;
      prevHHRecord.clients.forEach((pc) => {
        if (pc.id === c.id) {
          prevClient = pc;
        }
      });
      if (prevClient) {
        c.records.assets.forEach((a) => {
          const prevAsset = prevClient.records.assets.find((pa) => pa.id === a.id);
          if (prevAsset) {
            a.displays = prevAsset.displays;
          }
        });
      }
    });
    setHousehold(hh);
    const sortedNotes = hh.notes.sort((a, b) => {
      return moment(b.created_at).diff(moment(a.created_at));
    });
    setCurrentNotes(sortedNotes.slice(0, 2));
    setSnackbarMessage('Saved');
    setSnackbarOpen(true);
    setDisplays({ ...displays });
  };

  const handleSaveHousehold = async () => {
    setSubmitting(true);
    setErrors({});
    setScpErrors([]);
    setBsfErrors([]);
    try {
      const toSave = omit(household, [
        'notes',
        'agent_id',
        'access_level',
        'agent',
        'agent_firstname',
        'agent_lastname',
        'agent_num',
        'agent_email',
        'client_firstname',
        'client_lastname',
        'client_num',
        'client_email',
        'agent_deleted',
        'client_deleted',
      ]);
      // TODO clean up some other way
      household.clients.forEach((c) => {
        updateDefaults(c);
      });
      const result = await apiFetch.put(`/users/${user.id}/household/${householdId}/update`, toSave);
      if (result.value) {
        updateLocalHH(result.value);
      }
      if (result.errors) {
        const newErrors = {};
        result.errors.forEach((e) => {
          if (!newErrors[e.ctx]) {
            newErrors[e.ctx] = {};
          }
          if (e.client) {
            newErrors[e.ctx][e.client] = e;
          } else {
            newErrors[e.ctx].all = e;
          }
        });
        setErrors(newErrors);
      }
    } catch (e) {
      // console.log(e);
    }
    // document.location.reload();
    setSubmitting(false);
  };

  if (!household) {
    return (
      <div className={classes.loadingCircle}>
        <CircularProgress size={100} />
      </div>
    );
  }

  const { clients } = household;

  let hasNew = false;
  clients.forEach((c) => {
    if (c.isNew) {
      hasNew = true;
    }
  });

  const startAddClient = () => {
    const client = newClient(household.id);
    displays.newclient = displays.newclient || {};
    displays.newclient.display_header = true;
    clients.push(client);
    updateAll();
  };

  return (
    <Layout>
      {!viewSettings ? (
        <div>
          <div className={classes.sideNav} >
            <div style={styles.btnContainer} >
              <>
                <Tooltip title="Add a new client to this household">
                  <IconButton
                    style={styles.navBtn}
                    onClick={() => {
                      if (user.proxy) {
                        setConfirmAddClient(true);
                      } else {
                        startAddClient();
                      }
                    }}
                    disabled={hasNew || submitting}
                  >
                    <PersonAddIcon/>
                  </IconButton>
                </Tooltip>
                <Dialog open={confirmAddClient}>
                  <DialogTitle>
                    You are attempting to add a client for another agent.
                    <br/><br/>
                    Are you sure you want to do this?
                  </DialogTitle>
                  <div>
                    <div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmAddClient(false);
                          startAddClient();
                        }}
                        style={{ margin: '10px' }}
                      >
                        Ok
                      </Button>
                      <Button
                        onClick={() => {
                          setConfirmAddClient(false);
                        }}
                        style={{ margin: '10px' }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </>
              {household.access_level === 'OWNER' ? (
                <Tooltip
                  title="Configure settings for this household"
                >
                  <IconButton
                    style={styles.navBtn}
                    onClick={() => {
                      setViewSettings(true);
                    }}
                    disabled={submitting}
                  >
                    <SettingsIcon/>
                  </IconButton>
                </Tooltip>
              ) : ''}
              {household.access_level !== 'READ' ? (
                <Tooltip title="Save">
                  <IconButton
                    style={styles.navBtn}
                    onClick={handleSaveHousehold}
                    disabled={submitting}
                  >
                    <SaveIcon/>
                  </IconButton>
                </Tooltip>
              ) : ''}
            </div>
            <ScrollLink reff={personalRef} title="Personal Info" ctx="client"/>
            <ScrollLink reff={employmentRef} title="Employment Info" ctx="employment"/>
            <ScrollLink reff={beneficiariesRef} title="Beneficiary Info"/>
            <ScrollLink reff={trustsRef} title="Estate/Trust Info"/>
            <ScrollLink reff={incomeRef} title="Income & Expenses"/>
            <ScrollLink reff={assetsRef} title="Assets" ctx="asset"/>
            <ScrollLink reff={insuranceRef} title="Insurance" ctx="termInsurance"/>
            <ScrollLink reff={realEstateRef} title="Real Estate"/>
            <ScrollLink reff={trustedAdvisorsRef} title="Trusted Advisors"/>
            <ScrollLink reff={bsfRef} title="BSFs"/>
            <ScrollLink reff={clientDocsRef} title="Documents"/>
            <ScrollLink reff={banksRef} title="Banking Info"/>
            <ScrollLink reff={notesRef} title="Notes"/>
          </div>
          <div className={classes.content}>
            {household.agent && (household.agent.id !== user.id) ? (
              <Typography variant="h5">
                Primary Agent: {household.agent.firstname} {household.agent.lastname} #{household.agent.num}
              </Typography>
            ) : ''}
            <div id="personal_information" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={personalRef}>Personal Information</Typography>
              {clients.map((client, idx) => {
                const dupes = errors?.client?.all?.dupes;
                let dupeByEmail = false;

                if (dupes && client.email) {
                  // console.log('dupes', dupes);
                  dupes.forEach((d) => {
                    if (d.dupeByEmail && d.email && (d.email.toLowerCase() === ('' + client.email).toLowerCase())) {
                      dupeByEmail = true;
                    }
                  });
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_header"
                    key={client.id || 'newclient'}
                    ctx="client"
                  >
                    <>
                      {client.isNew && dupes ? (
                        <div style={{ margin: '15px' }}>
                          <DupeClients
                            dupes={dupes}
                            user={user}
                            householdId={householdId}
                            client={client}
                            shareRequested={() => {
                              setSnackbarMessage('Share request sent');
                              handleSnackbarOpen();
                            }}
                          />
                          {!dupeByEmail ? (
                            <div className={classes.ignoreBtn}>
                              <Button
                                onClick={() => {
                                  household.ignore_dupes = true;
                                  handleSaveHousehold();
                                }}
                              >
                                Ingore Duplicates and Save
                              </Button>
                            </div>
                          ) : ''}
                        </div>
                      ) : ''}
                      <ClientForm
                        key={client.id ? client.id : 'newclient'}
                        client={client}
                        isNew={client.isNew}
                        genders={orderBy(configs.genders, ['order_val'])}
                        taxFilingStatusTypes={configs.taxFilingStatusTypes}
                        scpErrors={scpErrors.length ? scpErrors.find((e) => e.id === client.id)?.errors : {}}
                        bsfErrors={bsfErrors.length ? bsfErrors.find((e) => e.id === client.id)?.errors : {}}
                        update={(record) => {
                          clients[idx] = record;
                        }}
                      />
                    </>
                  </Collapsable>
                );
              })}
            </div>
            <div id="employment_information" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={employmentRef}>Employment Information</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_employment"
                    key={client.id}
                    ctx="employment"
                  >
                    <ClientEmploymentList
                      client={client}
                      isNew={false}
                      updateAll={updateAll}
                      employmentStatusTypes={configs.employmentStatusTypes}
                      employmentOccupationTypes={configs.employmentOccupationTypes}
                      scpErrors={scpErrors.length ? scpErrors.find((e) => e.id === client.id)?.errors?.employment : {}}
                      key={client.id}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="beneficiary_information" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={beneficiariesRef}>Beneficiary Information</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_beneficiary"
                    key={client.id}
                    ctx="beneficiaries"
                  >
                    <ClientBeneficiariesList
                      client={client}
                      updateAll={updateAll}
                      relationshipTypes={configs.relationshipTypes}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="estate_trust_information" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={trustsRef}>Estate/Trust Information</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_trust"
                    key={client.id}
                    ctx="trusts"
                  >
                    <ClientTrustsList
                      client={client}
                      trustTypes={configs.trustTypes}
                      trustDistinctionTypes={configs.trustDistinctionTypes}
                      updateAll={updateAll}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="income_expenses" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={incomeRef} >Income &amp; Expenses</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_income"
                    key={client.id}
                    ctx="income"
                  >
                    <ClientIncomeList
                      client={client}
                      incomeSources={configs.incomeSources}
                      updateAll={updateAll}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="assets" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={assetsRef} >Assets</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                const cDisplay = displays[client.id] || {};
                return (
                  <Collapsable
                    client={client}
                    name="display_assets"
                    key={client.id}
                    ctx="asset"
                  >
                    <ClientAssetsList
                      client={client}
                      insuranceTypes={configs.insuranceTypes}
                      carriers={configs.carriers}
                      institutions={configs.institutions}
                      assetTypes={configs.assetTypes}
                      holdingTypes={configs.holdingTypes}
                      realEstateTypes={configs.realEstateTypes}
                      annuityTypes={configs.annuityTypes}
                      fundTypes={configs.fundTypes}
                      debtTypes={configs.debtTypes}
                      employerPlanTypes={configs.employerPlanTypes}
                      cDisplay={cDisplay}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="insurance" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={insuranceRef}>Insurance</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_insurance"
                    key={client.id}
                    ctx="termInsurance"
                  >
                    <ClientTermInsuranceList
                      client={client}
                      insuranceTypes={configs.insuranceTypes}
                      carriers={configs.carriers}
                      institutions={configs.institutions}
                      termInsuranceTypes={configs.termInsuranceTypes}
                      updateAll={updateAll}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="real_estate" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={realEstateRef}>Real Estate</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_realestate"
                    key={client.id}
                    ctx="realEstate"
                  >
                    <ClientRealEstateList
                      client={client}
                      realEstateTypes={configs.realEstateTypes}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="trusted_advisors" key="ta_section" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={trustedAdvisorsRef}>Trusted Advisors</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_advisors"
                    key={client.id}
                    id={'c_advisors' + client.id}
                    ctx="advisors"
                  >
                    <ClientAdvisorsList
                      advisors={client.records.advisors}
                      id={'advisors' + client.id}
                      client={client}
                      trustedAdvisors={configs.trustedAdvisorTypes}
                      updateAll={updateAll}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="bsfs" key="bsfs_section" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={bsfRef}>BSFs</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_bsfs"
                    key={client.id}
                    id={'c_bsfs' + client.id}
                    ctx="bsfs"
                  >
                    <ClientBSFs
                      id={'bsfs' + client.id}
                      client={client}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="clientDocs" key="clientDocs_section" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={clientDocsRef}>Documents</Typography>
              <div style={{ marginLeft: '30px' }}>Max File Size 50 MB</div>
              <ClientDocs
                household={household}
                updateAll={updateAll}
              />
            </div>
            <div id="real_estate" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={banksRef}>Banking Info</Typography>
              {clients.map((client) => {
                if (client.isNew) {
                  return '';
                }
                return (
                  <Collapsable
                    client={client}
                    name="display_banks"
                    key={client.id}
                    ctx="banks"
                  >
                    <ClientBankingList
                      client={client}
                    />
                  </Collapsable>
                );
              })}
            </div>
            <div id="notes" style={{ overflowX: 'auto' }}>
              <Typography variant="h4" ref={notesRef}>Notes</Typography>
              <HouseholdNotesList
                household={household}
                currentNotes={currentNotes}
                updateAll={updateAll}
              />
            </div>
            <br/><br/><br/>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={(
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          />
        </div>
      ) : (
        <HouseholdSettings
          household={household}
          handleClose={() => {
            setViewSettings(false);
          }}
          updateLocalHH={updateLocalHH}
          displays={displays}
        />
      )}
      <Dialog open={openSubmissionsDialog} onClose={() => setOpenSubmissionsDialog(false)}>
        <DialogTitle>
          Submission Requests
        </DialogTitle>
        <List>
          {submissionForms.map((form, idx) => {
            return (
              <ListItemButton
                sx={{ pl: 4 }}
                key={idx}
                onClick={() => handleScpForm(form.link)}
              >
                <ListItemText primary={`Submissions for ${form.name}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Dialog>
      <Dialog open={openBeneficiaryDialog} onClose={() => setOpenBeneficiaryDialog(false)}>
        <DialogTitle sx={{ marginLeft: 0 }}>Beneficiary Information</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 14 }}>
            <strong>Attention:</strong> {beneficiaryWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenBeneficiaryDialog(false);
            }}
            style={{ margin: '10px' }}
          >
            Back
          </Button>
          <Button
            gold
            onClick={() => {
              handleScpForm(formLink, true);
            }}
            style={{ margin: '10px' }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Household;

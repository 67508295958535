/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable no-trailing-spaces */

function getModel(score) {
  if (score < 14) {
    return 'Income';
  }
  if (score < 24) {
    return 'Conservative';
  }
  if (score < 35) {
    return 'Balanced / Moderate';
  }
  return 'Aggressive';
}

function calcScore(p) {
  let score = 0;
  score += p.return_type;
  score += p.years_need;
  score += p.expected_5_year;
  score += p.inflaction_concern;
  score += p.long_term_delcine_10;
  score += p.long_term_delcine_20;
  score += p.annual_return;
  score += p.invested_stocks_bonds;
  score += p.choosing_investment;
  return score;
}

const rps = {
  return_type: {
    question: 'What type of returns are you seeking?',
    answers: [
      { value: 2, label: 'Income' },
      { value: 3, label: 'Growth greater than inflation' },
      { value: 4, label: 'Maximum Growth Potential' },
    ],
  },
  years_need: {
    question: 'In approximately how many years would you expect to need this money?',
    answers: [
      { value: 2, label: '1-3 years' },
      { value: 3, label: '3-7 years' },
      { value: 4, label: '7+ years' },
    ],
  },
  expected_5_year: {
    question: 'Which one of the following describes your expected future earnings over the next five years?',
    answers: [
      { value: 1, label: 'I expect my earnings to decrease (retirement, part- time work, economically-depressed industry, etc.)' },
      { value: 2, label: 'I expect my earnings to keep pace with inflation.' },
      { value: 3, label: 'I expect my earnings to increase and stay somewhat ahead of inflation.' },
      { value: 4, label: 'I expect my earnings to increase and far outpace inflation (due to promotions, new job, etc.)' },
    ],
  },
  inflaction_concern: {
    question: 'How concerned are you that your savings and investment dollars outpace inflation?',
    answers: [
      { value: 2, label: 'Not very concerned' },
      { value: 3, label: 'Moderately concerned' },
      { value: 4, label: 'Highly concerned' },
      { value: 5, label: 'Very highly concerned' },
    ],
  },
  long_term_delcine_10: {
    question: 'How would you react if the value of your long-term investments declined by 10% in one year?',
    answers: [
      { value: 1, label: 'I understand there may be market fluctuations, but I would be very concerned if my investments declined by 10%.' },
      { value: 2, label: 'I accept some market fluctuations, but I would be somewhat concerned if my investments declined this much.' },
      { value: 3, label: 'I generally invest for the long term and I would not be concerned with this decline.' },
      { value: 4, label: 'I invest for the long term and would accept a 10% decline.' },
    ],
  },
  long_term_delcine_20: {
    question: 'How would you react if the value of your long-term investments declined by 20% in one year?',
    answers: [
      { value: 1, label: 'I understand there may be market fluctuations, but I would be very concerned if my investments declined 20%.' },
      { value: 2, label: 'I accept some market fluctuations, but I would be somewhat concerned if my investments declined this much.' },
      { value: 3, label: 'I generally invest for the long term and I would not be concerned with this decline.' },
      { value: 4, label: 'I invest for the long term and would accept a 20% decline.' },
    ],
  },
  annual_return: {
    question: 'Approximately what annual rate of return would you want/ need to meet your goals?',
    answers: [
      { value: 2, label: 'Less than 5%' },
      { value: 3, label: '5% - 10%' },
      { value: 4, label: 'Greater than 10%' },
    ],
  },
  invested_stocks_bonds: {
    question: 'Have you ever invested in individual stocks/bonds or stocks/bonds mutual funds?',
    answers: [
      { value: 1, label: 'No, but if I had, the fluctuations would make me uncomfortable.' },
      { value: 2, label: 'No, but if I had, I would be comfortable with the fluctuations in order to receive the potential for higher returns.' },
      { value: 3, label: 'Yes, I have, but I was uncomfortable at times with the fluctuations, despite the potential for higher returns.' },
      { value: 4, label: 'Yes, I have, and I felt comfortable with the fluctuations in order to receive the potential for higher returns.' },
    ],
  },
  choosing_investment: {
    question: 'Which of the following statements best describes your feelings toward choosing your investments?',
    answers: [
      { value: 2, label: 'I would select investments that have a low degree of risk associated with them.' },
      { value: 4, label: 'I prefer investments with mostly a low degree of risk with a small portion that have a higher degree of risk.' },
      { value: 6, label: 'I prefer to diversify with a balanced mix of investments.' },
      { value: 8, label: 'I prefer investments with mostly an aggressive degree of risk with a small portion that have a lower degree of risk.' },
      { value: 12, label: 'I would only select investments that have a higher degree of risk and a greater potential for higher returns.' },
    ],
  },
  maximum_loss: {
    question: 'What is the maximum loss you would accept before you would begin to feel very uncomfortable?',
    answers: [
      { value: 5, label: '0% to -5% Loss' },
      { value: 10, label: '-6% to -10% Loss' },
      { value: 15, label: '-11% to -15% Loss' },
      { value: 20, label: '-16% to -20% Loss' },
      { value: 25, label: '-21% to -25% Loss' },
      { value: 30, label: '-26% to -30% Loss' },
      { value: 35, label: '-31% to -35% Loss' },
      { value: 40, label: '-36% to -40% Loss' },
      { value: 45, label: '-41% to -45% Loss' },
      { value: 50, label: '-46% to -50% Loss' },
      { value: 55, label: '-51% to -55% Loss' },
      { value: 60, label: '-56% to -60% Loss' },
    ],
  },
};

rps.getModel = getModel;
rps.calcScore = calcScore;

rps.modelText = {
  Conservative: 'Conservative objective is for an investor who would like to exceed long-term inflation by a small margin and accepts a low to moderate degree of short-term volatility. It is for an investor who seeks both modest capital appreciation and income. While this portfolio is still designed to preserve the investor\'s capital, small fluctuations in the value of the portfolio may occur.',
  'Balanced/Moderate': 'Balanced objective is for a client who accepts a fair degree of risk and is looking to exceed long-term inflation by a fair margin (e.g. 3-5% over the long term). The investor understands, and is comfortable with the fact that short-term volatility is a price to be paid for higher long-term returns. The main objective is to achieve steady portfolio growth while limiting fluctuations in the value of their portfolio to less than those of the overall equity markets.',
  Aggressive: 'Aggressive objective is for an investor looking to exceed long-term inflation by a significant margin and can accept a moderate to high degree of short-term volatility. It is for an investor who seeks above-average growth with a long-term time horizon.',
};

export default rps;

// What is the maximum loss you would accept before you would begin to feel very uncomfortable?	
// 		0% to -5% Loss		-15% to -20% Loss		-30% to -35% Loss		-45% to -50% Loss					
// 		-5% to -10% Loss		-20% to -25% Loss		-35% to -40% Loss		-50% to -55% Loss					
// 		-10% to -15% Loss		-25% to -30% Loss		-40% to -45% Loss		-55% to -60% Loss					

// return_type: Joi.number().integer(),
// years_need: Joi.number().integer(),
// expected_5_year: Joi.number().integer(),
// inflaction_concern: Joi.number().integer(),
// long_term_delcine_10: Joi.number().integer(),
// long_term_delcine_20: Joi.number().integer(),
// annual_return: Joi.number().integer(),
// invested_stocks_bonds: Joi.number().integer(),
// choosing_investment: Joi.number().integer(),
// maximum_loss: Joi.number().integer(),

// 1. What type of returns are you seeking?		
// 2		Income											
// 3		Growth greater than inflation											
// 4		Maximum Growth Potential											
	
// 2. In approximately how many years would you expect to need this money?	
// 2		1-3 years											
// 3		3-7 years											
// 4		7+ years											
	
// 3. Which one of the following describes your expected future earnings over the next five years?	
// 1		I expect my earnings to decrease (retirement, part- time work, economically-depressed industry, etc.)											
// 2		I expect my earnings to keep pace with inflation.											
// 3		I expect my earnings to increase and stay somewhat ahead of inflation.											
// 4		I expect my earnings to increase and far outpace inflation (due to promotions, new job, etc.)											
	
// 4. How concerned are you that your savings and investment dollars outpace inflation?	
// 2		Not very concerned											
// 3		Moderately concerned											
// 4		Highly concerned											
// 5		Very highly concerned											
	
// 5. How would you react if the value of your long-term investments declined by 10% in one year?	
// 1		I understand there may be market fluctuations, but I would be very concerned if my investments declined by 10%.											
// 2		I accept some market fluctuations, but I would be somewhat concerned if my investments declined this much.											
// 3		I generally invest for the long term and I would not be concerned with this decline.											
// 4		I invest for the long term and would accept a 10% decline.											
	
// 6. How would you react if the value of your long-term investments declined by 20% in one year?	
// 1		I understand there may be market fluctuations, but I would be very concerned if my investments declined 20%.											
// 2		I accept some market fluctuations, but I would be somewhat concerned if my investments declined this much.											
// 3		I generally invest for the long term and I would not be concerned with this decline.											
// 4		I invest for the long term and would accept a 20% decline.											
	
// 7. Approximately what annual rate of return would you want/ need to meet your goals?	
// 2		Less than 5%											
// 3		5% - 10%											
// 4		Greater than 10%											
	
// 8. Have you ever invested in individual stocks/bonds or stocks/bonds mutual funds?	
// 1		No, but if I had, the fluctuations would make me uncomfortable.											
// 2		No, but if I had, I would be comfortable with the fluctuations in order to receive the potential for higher returns.											
// 3		Yes, I have, but I was uncomfortable at times with the fluctuations, despite the potential for higher returns.											
// 4		Yes, I have, and I felt comfortable with the fluctuations in order to receive the potential for higher returns.											
	
// 9. Which of the following statements best describes your feelings toward choosing your investments?	
// 2		I would select investments that have a low degree of risk associated with them.											
// 4		I prefer investments with mostly a low degree of risk with a small portion that have a higher degree of risk.											
// 6		I prefer to diversify with a balanced mix of investments.											
// 8		I prefer investments with mostly an aggressive degree of risk with a small portion that have a lower degree of risk.											
// 12		I would only select investments that have a higher degree of risk and a greater potential for higher returns.

import React from 'react';
import moment from 'moment';

import { Box, MenuItem } from '@mui/material';
import { MaskInput, Select, TextField } from '../lib/common';
import DeleteButton from './delete-button';
import states from '../lib/states';

const ClientTrustForm = (props) => {
  const {
    update, trust, trustTypes, trustDistinctionTypes,
  } = props;

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    update({ ...trust, [name]: value });
  };

  const handleDelete = () => {
    trust.to_delete = true;
    update(trust);
  };

  const {
    name,
    trust_date,
    trust_updated,
    percentage,
    type_id,
    distinction_type_id,
    ein,
    gov_state,
  } = trust;

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    update({ ...trust, [name]: moment(value).toISOString() });
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(15, 1fr)"
      gap={2}
      minWidth={985}
    >
      <Box gridColumn="span 6">
        <TextField
          label="Trust Name"
          id="name"
          name="name"
          value={name}
          onChange={handleTextChange}
        />
      </Box>
      <Box gridColumn="span 3">
        <TextField
          label="Trust Date"
          value={trust_date}
          name="trust_date"
          onChange={handleDateChange}
          type="date"
        />
      </Box>
      <Box gridColumn="span 3">
        <TextField
          label="Last reviewed/updated"
          value={trust_updated}
          name="trust_updated"
          onChange={handleDateChange}
          type="date"
        />
      </Box>
      <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
        <DeleteButton handleDelete={handleDelete}/>
      </Box>
      <Box gridColumn="span 3">
        <TextField
          label="Percentage to Receive"
          value={percentage}
          name="percentage"
          type="number"
          onChange={handleTextChange}
        />
      </Box>
      <Box gridColumn="span 3">
        <Select
          label="Trust Type"
          value={type_id}
          name="type_id"
          onChange={handleTextChange}
        >
          {
            trustTypes
              .sort((a, b) => a.order_val - b.order_val)
              .map((trust) => <MenuItem value={trust.id} key={trust.id}>{trust.name}</MenuItem>)
          }
        </Select>
      </Box>
      <Box gridColumn="span 3">
        <Select
          label="Additional Distinction"
          value={distinction_type_id}
          name="distinction_type_id"
          onChange={handleTextChange}
        >
          {
            trustDistinctionTypes
              .map((trust) => <MenuItem value={trust.id} key={trust.id}>{trust.name}</MenuItem>)
          }
        </Select>
      </Box>
      <Box gridColumn="span 3">
        <MaskInput
          label="Trust EIN"
          value={ein}
          name="ein"
          mask_type="ein"
          onChange={handleTextChange}
        />
      </Box>
      <Box gridColumn="span 3">
        <Select
          label="Governing State"
          value={gov_state}
          name="gov_state"
          onChange={handleTextChange}
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </Select>
      </Box>
    </Box>
  );
};

export default ClientTrustForm;

import React, { useState } from 'react';
import { Button, GridForm } from '../lib/common';

import ClientBanksForm from './client-banks-form';

const BanksList = (props) => {
  const { client } = props;
  const [banks, setBanks] = useState(client.records.banks);

  function newBank() {
    return {
      type: '',
      bank_name: '',
      name_on_account: '',
      account_number: '',
      routing_number: '',
      isNew: true,
    };
  }

  return (
    <GridForm>
      {banks.filter((r) => !r.to_delete).map((r, idx) => {
        return (
          <ClientBanksForm
            key={idx}
            index={idx}
            client={client}
            bank={r}
            isNew={client.isNew}
            update={(record) => {
              banks[idx] = record;
              client.records.banks = banks.slice();
              setBanks(client.records.banks);
            }}
          />
        );
      })}
      <Button
        householdadd
        style={{ margin: '15px' }}
        onClick={() => {
          const newPolicy = newBank();
          banks.push(newPolicy);
          client.records.banks = banks.slice();
          setBanks(client.records.banks);
        }}
      >
        Add Bank
      </Button>

    </GridForm>
  );
};

export default BanksList;

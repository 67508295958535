import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment/moment';
import useSWR from 'swr';

import useUser from '../lib/useUser';
import { formatCurrency } from '../lib/util';
import { Button } from '../lib/common';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{formatCurrency(Number(params.row.amount / 100).toFixed(2))}</span>;
    },
  },
  {
    field: 'statement_date',
    headerName: 'Date',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('DD-MMM-YYYY') : ''}</span>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 150,
    renderCell: () => {
      return '';
    },
  },
];

const Billing = () => {
  const user = useUser();
  const classes = useStyles();

  const path = `/users/${user.id}/billing/paid`;
  const { data: payments, loading: paymentsLoading } = useSWR(path);
  const { data: subs } = useSWR(`/users/${user.id}/billing/subscriptions`);
  const subsData = subs || [];
  let activeSub = false;
  subsData.forEach((s) => {
    if (s.status === 'active') {
      activeSub = true;
    }
  });

  return (
    <div>
      <div
        style={{
          margin: '10px',
        }}
      >
        {!user.pc || !activeSub ? (
          <Button
            onClick={async () => {
              const start = await apiFetch('/subscription_start');
              if (start.hasExisting) {
                document.location.href = 'settings';
                return;
              }
              document.location.href = start.url;
            }}
          >
            start subscription
          </Button>
        ) : (
          <Button
            onClick={async () => {
              const start = await apiFetch('/subscriptions/portal');
              document.location.href = start.url;
            }}
          >
            manage subscription
          </Button>
        )}
      </div>
      {!paymentsLoading ? (
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={payments || []} />
        </div>
      ) : <CircularProgress/>}
    </div>
  );
};

export default Billing;
